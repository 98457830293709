import { forwardRef, FC, HTMLAttributes, RefObject, useRef } from 'react';
import { TextInput } from 'src/components/ui-components/TextInput';
import { Icon } from 'src/components/ui-components/Icon';
import { Tooltip } from 'src/components/ui-components/Tooltip';
// This needs to be imported with relative path
// otherwise consuming app wouldn't know where
// to find the types
import { TextInputWithLabelProps } from '../TextInput';
import styles from './SearchInput.module.scss';

interface SharedProps {
  clearInputText: string;
}

interface ClearButtonProps extends SharedProps, HTMLAttributes<HTMLButtonElement> {
  clearInput: () => void;
  inputElement: RefObject<HTMLInputElement>;
}

const ClearButton: FC<ClearButtonProps> = ({ clearInput, clearInputText, inputElement }) => {
  const focusInputAfterClear = () => {
    inputElement.current?.focus();
    clearInput();
  };

  return (
    <Tooltip label={clearInputText}>
      <button
        onClick={focusInputAfterClear}
        type="button"
        className={styles.clearInput}
        data-automation-id="SearchInputClearButton"
      >
        <Icon iconName="cross" size="small" />
      </button>
    </Tooltip>
  );
};

type InputRef = HTMLInputElement;

export interface SearchInputProps extends SharedProps, Omit<TextInputWithLabelProps, 'inputIcon'> {
  clearInput: () => void;
  value: string;
}

export const SearchInput = forwardRef<InputRef, SearchInputProps>(
  ({ value, label, clearInput, clearInputText, ...props }, ref) => {
    const localRef = useRef<HTMLInputElement>(null);
    const inputRef = ref || localRef;

    return (
      <TextInput
        inputIcon={!value ? 'search' : 'cross'}
        value={value}
        ref={inputRef}
        label={label}
        {...props}
      >
        {value && (
          <ClearButton
            clearInputText={clearInputText}
            clearInput={clearInput}
            inputElement={inputRef as React.MutableRefObject<HTMLInputElement>}
          />
        )}
      </TextInput>
    );
  },
);
