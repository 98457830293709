import { useGetLocale } from 'src/components/ui-components';
import { getDateStringFromSiteLocale } from 'src/utils/date/date';
import { formatTime } from 'src/utils/time';
import { RTRow } from '../../types/resourcePlanner';

interface GeneralColumnRendererProps {
  id?: string | undefined;
  format?: string | undefined;
  row: RTRow;
  value: string;
}

export const GeneralColumnRenderer = ({ id, format, row, value }: GeneralColumnRendererProps) => {
  const siteLocale = useGetLocale();

  if (format === 'date' && value) {
    let dateString = '';

    if (id === 'startsAt' && row.original.startsAt) {
      dateString = row.original.startsAt.substring(0, row.original.startsAt.indexOf('T'));
    }

    if (id === 'endsAt' && row.original.endsAt) {
      dateString = row.original.endsAt.substring(0, row.original.endsAt.indexOf('T'));
    }

    if (dateString) {
      const [year, month, day] = dateString.split('-');

      const monthString = month.length === 1 ? `0${month}` : month;
      const dayString = day.length === 1 ? `0${day}` : day;

      const date = new Date(`${year}-${monthString}-${dayString}T00:00:00`);
      return getDateStringFromSiteLocale(date, siteLocale);
    }
  }

  if (id === 'status' && row.original.translatedStatus) {
    return row.original.translatedStatus;
  }

  if (format === 'number') {
    const formatTimeValue = value ? formatTime(value, siteLocale) : formatTime('0', siteLocale);
    return formatTimeValue;
  }

  return value === undefined ? '' : value;
};
